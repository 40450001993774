body {
    margin: 0;
    font-family: Arial, sans-serif;
    scroll-behavior: smooth;
  }
  
  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .first-section {
    color: white;
  }
  
  .second-section {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .third-section {
    background-color: #333;
    color: white;
  }
  
  .content {
    max-width: 800px;
    padding: 20px;
  }
  
  .logo {
    width: 30vw;
    height: auto;
    margin-bottom: 20px;
  }
  
  .text {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .scroll-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .scroll-btn:hover {
    background-color: #0056b3;
  }
  
  .about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 40px;
  }
  
  .carousel-container {
    flex: 1;
    max-width: 50%;
  }
  
  .description-container {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .description-container h2 {
    margin-bottom: 20px;
  }
  
  .description-container p,
  .description-container ul {
    margin-bottom: 10px;
  }
  
  .carousel-slide img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .carousel-slide .legend {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
  }

  .about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 40px;
  }
  
  .carousel-container {
    flex: 1;
    max-width: 50%;
  }
  
  .description-container {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      padding: 20px;
    }
  
    .carousel-container,
    .description-container {
      max-width: 100%;
      padding: 0;
    }
  
    .carousel-container {
      margin-bottom: 20px;
    }
  
    .description-container h2 {
      font-size: 0.8rem;
    }
  
    .description-container p,
    .description-container ul {
      font-size: 0.8rem;
    }
  }
  
  